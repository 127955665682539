import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import ReactSwipeEvents from "react-swipe-events";
import Header from "../components/Header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { faHeart, faXmark } from '@fortawesome/free-solid-svg-icons';
import { graphql } from "gatsby";
import { useForm, ValidationError } from '@formspree/react';

const IndexPage = ({ data: { site, background1, background2 } }) => {
  const [step, setStep] = useState(1);
  const [pocket, setPocket] = useState(true);
  const [state, handleSubmit] = useForm("skapsami-lead");

  const pickPocketNo = () => {
      setPocket(false);
      typeof window !== "undefined" && window.gtag && window.gtag("event", "pocket-no");
      setStep(2);
  }

  const pickPocketYes = () => {
      setPocket(true);
      typeof window !== "undefined"&& window.gtag && window.gtag("event", "pocket-yes");
      setStep(2);
  }

  const submit = (e) => {
    handleSubmit(e);
    typeof window !== "undefined"&& window.gtag && window.gtag("event", "pocket-lead");
  }

  return (
    <Layout siteMetadata={site.siteMetadata}>
      <TransitionGroup className={pocket ? 'swiped yes' : 'swiped no'}>
        {step === 1 && (
          <CSSTransition key="1" timeout={1000} classNames="blink-in-swipe-out">
            <ReactSwipeEvents onSwipedLeft={pickPocketNo} onSwipedRight={pickPocketYes}>
              <div className="landing-bg-container">
                <GatsbyImage className="screen" image={getImage(background1)} alt="S kapsami" />
              </div>
              <div className="screen screen-like">
                <div className="screen-content">
                  <Header />
                  <p className="g">Šaty s velkou kapsou? Proč ne?</p>
                  <p className="g">S kapsami je o poskytnutí místa pro pohodlné uložení všech vašich věcí, abyste mohli žít svůj život a neměli plné ruce práce! Víme, že je těžké najít šaty s velkými kapsami, ale my jsme tu na to aby jsme vám pomohli.</p>
                  <span className="flex-grow"></span>
                  <h2>Šaty <span className="age">27</span></h2>
                  <p>Také miluješ kapsy?</p>
                </div>
                <div className="options">
                  <button className="no" onClick={pickPocketNo}>
                    <FontAwesomeIcon icon={faXmark} />
                    <span>No</span>
                  </button>
                  <button className="yes" onClick={pickPocketYes}>
                    <FontAwesomeIcon icon={faHeart} />
                    <span>Yes</span>
                  </button>
                </div>
              </div>
            </ReactSwipeEvents>
          </CSSTransition>
        )}
        {step === 2 && (
          <CSSTransition key="2" timeout={1000} classNames="blink-in-swipe-out">
            <div>
              <div className="landing-bg-container">
                <GatsbyImage className="screen" image={getImage(background2)} alt="S kapsami" />
              </div>
              <div className="screen screen-lead">
                <div className="screen-content">
                  <Header />
                  <span className="flex-grow"></span>
                  <p>Zatím jsme nespustili prodej, ale moc rádi tě kontaktujeme až budeme připraveni.</p>
                </div>
                <form className="options" onSubmit={submit}>
                  {state.succeeded ? (
                    <div className="thanks">
                      <FontAwesomeIcon icon={faHeart} />
                      <span>Děkujeme</span>
                    </div>
                  ) : (
                    <>
                      <label htmlFor="email">Email</label>
                      <input type="email" id="email" name="email" />
                      <button type="submit" disabled={state.submitting}>
                        Přihlásit
                      </button>
                      {state.errors.length > 0 && (
                        <div className="errors">
                          <ValidationError
                            errors={state.errors}
                          />
                        </div>
                      )}
                    </>
                  )}
                </form>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </Layout>
  )
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        author
        description
        siteUrl
        title
        keywords
        instagram
        twitter
        email
      }
    }
    background1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "background-1.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          blurredOptions: {
            width: 160
          }
          breakpoints: [350, 500, 600]
        )
      }
    }
    background2: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "background-2.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          blurredOptions: {
            width: 160
          }
          breakpoints: [350, 500, 600]
        )
      }
    }
  }
`;
